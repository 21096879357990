import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getLineUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/crm/get_lineusers', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/crm/get_lineuser', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/crm/add_lineuser', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/crm/edit_lineuser/${params.user_id}`, params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/crm/delete_lineuser/${params.user_id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getDefaultLineUser(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/crm/get_default_lineuser', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
